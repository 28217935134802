.newsletter-teaser {
  $root: &;
  position: relative;

  &__step-1 {
    color: $whiteColor;
    padding: 16px 10px 8px;
    background-position: 75%;

    @media (min-width: $screen-tablet-landscape) {
      padding: 24px 24px 16px;
    }

    #{$root}__row {
      &--head {
        max-width: 60%;
      }

      @media (min-width: $screen-tablet-sm) {
        max-width: 75%;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-width: 60%;
      }

      &--footer {
        padding: 0;
      }
    }
  }

  &__row {
    padding: 8px 0;

    h2,
    h3 {
      margin: 0 0 15px;
      font-weight: $headlineH2FontWeight;
    }

    p {
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: currentColor !important;
    }
  }

  &__headline {
    @include fontSize($fontSizeXXXLarge);
    font-weight: $fontWeightBold;
    line-height: 1.2;
    width: 100%;
  }

  &__text {
    display: block;
    font-size: $fontSizeXMedium;
    line-height: 1.1;
  }

  &__form {
    margin-top: 15px;
  }

  &__success {
    background: $whiteColor;
    padding: 8px;

    .newsletter-form__declaration,
    #{$root}__next-step,
    #{$root}__headline {
      text-align: center;
      color: $textBaseColor !important;
    }
  }

  .newsletter-form {
    &__declaration {
      font-size: $fontSizeXSmall !important;
      font-weight: $fontWeightThin;
      margin-top: -8px;
      display: block;
    }
  }

  .input__label,
  .radio-input__label {
    color: $textBaseColor;
  }

  .input-error {
    color: #f88;
  }

  &__next-steps {
    @include clearList();
    margin: 0;

    @media (min-width: $screen-tablet-sm) {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      margin: 0 auto 10px;
    }

    .icon::before {
      color: $brandPrimaryColor;
      font-size: 50px;
    }

    .icon--confirmationArrow::before {
      font-size: 12px;
      color: $grayDarkColor;
    }
  }

  &__next-step {
    font-size: $fontSizeXSmall;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 40px;
    padding: 0;
    text-align: center;
    width: 100%;

    @media (min-width: $screen-tablet-sm) {
      font-size: $fontSizeSmall;
      max-width: 200px;
      padding: 0 15px;
      margin-bottom: 15px;
      flex-basis: 30%;
    }

    &:first-child {
      padding-left: 0;
    }

    &--link {
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-sm) {
        transform: none;
      }
    }
  }

  &__icon-text {
    margin: 8px auto 0;
    max-width: 158px;

    @media (min-width: $screen-tablet-sm) {
      max-width: inherit;
    }
  }

  &--full-form {
    #{$root}__step-1 {
      #{$root}__header,
      #{$root}__headline {
        @include fontSize($fontSizeXXLarge);
        padding: 0;
        width: 100%;
        font-weight: $fontWeightSemiBold;
      }

      #{$root}__row {
        @media (min-width: $screen-tablet-portrait) {
          display: flex;
          flex-direction: row;
          padding: 10px 0;
          max-width: none;
        }
      }
    }

    .radio-input__label,
    .radio-input__input:checked ~ .radio-input__label {
      color: $whiteColor;
    }

    #{$root}__col-1 {
      @media (min-width: $screen-tablet-portrait) {
        float: left;
        min-width: 25%;
        max-width: none;
        padding-right: 15px;
        text-align: right;
      }

      .headline {
        text-align: left;
        margin: 0;
      }

      img {
        max-width: 100%;
      }
    }

    #{$root}__col-2 {
      padding: 15px 0 0;

      @media (min-width: $screen-tablet-portrait) {
        align-items: flex-end;
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        padding: 0 0 0 15px;
      }
    }

    #{$root}__step-2 {
      margin: 30px 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: 40px 0;
      }
    }
  }
}

.row--colored > .col-xs-12 > .newsletter-teaser--full-form {
  margin-top: -30px;
  margin-bottom: -30px;

  @media (min-width: $screen-tablet-portrait) {
    margin-top: -40px;
    margin-bottom: -40px;
  }
}

.grid__element--bg-color1,
.grid__element--bg-color2 {
  .newsletter-teaser--full-form {
    .newsletter-teaser__col-1,
    .newsletter-teaser__col-2,
    .newsletter-teaser__step-1,
    .newsletter-teaser__header,
    .radio-input__label,
    .newsletter-form__declaration a {
      color: $grayDarkColor !important;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
newsletter-teaser {
  .newsletter-teaser__flag,
  .newsletter-teaser__success {
    display: none;
  }
}
