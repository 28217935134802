@import '../../../bps/components/imageTextActionTeaser/imageTextActionTeaser.scss';

.image-text-action-teaser {
  $root: &;

  &__img {
    .teasergroup_teaser--asymmetric .image-text-action-teaser--variant1 & { // stylelint-disable-line
      overflow: hidden;
    }

    img {
      .teasergroup_teaser--asymmetric .teasergroup_teaser__item & { // stylelint-disable-line
        height: auto;
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          height: 100%;
          width: auto;
        }
      }

      @media (min-width: $screen-tablet-portrait) {
        .teasergroup_teaser--asymmetric .teasergroup_teaser__item:nth-child(1) & { // stylelint-disable-line
          margin: 0 auto 0 0;
        }

        .teasergroup_teaser--asymmetric .teasergroup_teaser__item:nth-child(2) & { // stylelint-disable-line
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &__box {
    background: $brandPrimaryColor;
    padding: 24px 48px 0;
    min-height: 330px;

    .image-text-action-teaser--variant1 & {
      .teasergroup_teaser--asymmetric .teasergroup_teaser__item & { // stylelint-disable-line
        @media (min-width: $screen-tablet-portrait) {
          min-height: 390px;
        }

        @media (min-width: $screen-tablet-landscape) {
          min-height: 340px;
        }
      }

      @media (min-width: $screen-tablet-portrait) {
        .teasergroup_teaser--asymmetric .teasergroup_teaser__item:nth-child(1) & { // stylelint-disable-line
          margin: 0 auto 0 0;
          max-width: 796px;
        }

        .teasergroup_teaser--asymmetric .teasergroup_teaser__item:nth-child(2) & { // stylelint-disable-line
          max-width: 364px;
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &__subline {
    line-height: 1.3;
    color: $whiteColor;
  }

  &__button {
    margin: 0 0 25px;
    border: 1px solid $whiteColor;

    &:hover {
      border-color: $whiteColor;
    }

    .teasergroup_teaser--asymmetric .image-text-action-teaser--variant1 & { // stylelint-disable-line
      border: none;
      font-weight: $fontWeightBold;
      text-decoration: underline;

      &::after {
        display: none;
      }
    }
  }

  &--teaser-wide {
    #{$root}__button {
      background: $buttonSecondaryBackgroundColor;
      border-color: $buttonSecondaryBorderColor;
      color: $buttonSecondaryTextColor;
      font-weight: $fontWeightBold;
      text-transform: uppercase;
      width: 100%;
      text-decoration: none;
      border-radius: $buttonBorderRadius;
      letter-spacing: 1.5px;

      @include iconFont(arrowRight, after);

      &::after {
        display: inline-block;
        padding-left: 8px;
        font-size: 10px;
        font-weight: $fontWeightBold;
        text-decoration: none;
      }

      &--hover,
      &:hover {
        background: $buttonSecondaryHoverBackgroundColor;
        color: $buttonSecondaryHoverTextColor;
        border-color: $buttonSecondaryHoverBackgroundColor;
        text-decoration: none;
      }
    }
  }

  &--parallax {
    margin: -30px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: -40px 0;
    }
  }

  .grid__element--teaser & {
    #{$root}__button {
      color: var(--grid-element-teaser-text-color);
      border: 1px solid var(--grid-element-teaser-text-color);
      background: transparent;

      &::after {
        color: var(--grid-element-teaser-text-color);
      }

      &:hover {
        background: transparent;
      }
    }
  }

  &--default,
  &--variant1 {
    #{$root}__img {
      display: block;
    }
  }

  &--variant2 {
    #{$root}__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      padding: 10% 10% 2%;

      @media (min-width: $screen-mobile-large) {
        padding: 10% 15% 2%;
      }
    }

    #{$root}__header {
      color: $whiteColor;
      text-align: left;
      order: 1;
      font-size: 24px;
      line-height: 1;
      display: flex;
      justify-content: left;
      align-items: flex-start;
      margin: 0;
      text-transform: uppercase;
      font-weight: $fontWeightThin;

      @media (min-width: $screen-mobile) {
        font-size: 36px;
      }

      @media (min-width: $screen-tablet-portrait) {
        font-size: 54px;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: 44px;
      }

      @media (min-width: $screen-desktop) {
        font-size: 54px;
      }

      .columns-1 & {
        margin-bottom: 10px;
      }
    }

    #{$root}__subline {
      color: $whiteColor;
      font-weight: $fontWeightBold;
      text-align: left;
      order: 2;
      flex-grow: 0;
      font-size: 18px;
      line-height: 1.1;
      display: flex;
      justify-content: left;
      align-items: flex-end;
      margin: 4px 0;
      padding: 0;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 28px;
        margin: 12px 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: 24px;
      }

      .columns-1 & {
        min-height: auto !important;
      }
    }

    #{$root}__button {
      text-align: center;
      order: 3;
      border: 1px solid $whiteColor;
      margin: 8px auto 0 0;
      text-transform: uppercase;
      background: transparent;
      width: auto;
      padding: 8px 16px;
      min-width: 150px;
      font-weight: $fontWeightBold;
      min-height: 45px;
      display: flex;
      justify-content: left;
      align-items: center;

      &::after {
        display: none;
      }

      &:hover {
        text-decoration: none;
        border: 1px solid $buttonPrimaryBackgroundColor;
        color: $buttonPrimaryTextColor;
        background: $buttonPrimaryBackgroundColor;
      }
    }

    #{$root}__img img {
      width: 100%;
      display: flex;
    }

    &#{$root}--with-content {
      #{$root}__content {
        background-color: rgb(0 0 0 / .15);
      }
    }
  }
}
