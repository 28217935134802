.image-text-action-teaser {
  $root: &;
  width: 100%;
  height: 100%;
  position: relative;
  border: $imageTextTeaserBorder;
  padding: $imageTextTeaserBorderPadding;

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  &__img {
    img {
      width: 100%;
      display: inherit;
    }
  }

  &__box {
    background: $whiteColor;
    background: $imageTextTeaserBoxBackground;
    padding: 16px;
    position: $imageTextTeaserBoxPosition;
    z-index: 1;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    min-height: $imageTextTeaserMinHeight;
    flex-grow: 2;
  }

  &__header {
    @include fontSize($imageTextTeaserHeadlineSize);
    color: $imageTextTeaserHeadlineColor;
    margin-bottom: 16px;
    line-height: 115%;
  }

  &__subline {
    color: $textBaseColor;
    padding: 0 0 16px;
    display: inline-block;
    line-height: 1.2;
    flex-grow: 2;
  }

  &__button {
    width: 100%;
    min-width: auto;
    color: $imageTextTeaserButtonColor;
    background: $imageTextTeaserButtonBackgroundColor;
    border-color: $imageTextTeaserButtonBorderColor;
    margin: 0 0 10px;

    &--hover,
    &:hover {
      background: $imageTextTeaserButtonBackgroundColor;
      color: $imageTextTeaserButtonColor;
    }
  }

  &--teaser-wide {
    #{$root}__link {
      width: 100%;
      text-decoration: none !important;

      @media (min-width: $screen-tablet-landscape) {
        flex-direction: row;
        justify-content: center;
      }
    }

    #{$root}__img-wrapper {
      position: relative;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
      }
    }

    #{$root}__img-text {
      position: absolute;
      top: 0;
      width: 100%;
      color: $whiteColor;
      text-align: center;
      display: flex;
      justify-content: center;

      h2,
      h3,
      h4 {
        padding: 16px 30px;
        line-height: 1.1;
        width: 100%;
        margin: 0;
        color: $whiteColor;
      }
    }

    #{$root}__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      text-align: center;
      min-height: auto;

      @media (min-width: $screen-tablet-landscape) {
        text-align: left;
        min-width: 40%;
        max-width: 40%;
        width: auto;
      }

      @media (min-width: $screen-desktop) {
        padding: 30px;
        min-width: 33%;
        max-width: 35%;
      }
    }

    #{$root}__header {
      font-size: 27px;
      color: $brandSecondaryColor;
      margin: 0 0 12px;
    }

    #{$root}__subline {
      font-size: $imageTextTeaserWideSublineFontSize;
      display: inline-block;
      width: 100%;
      color: $textBaseColor;
      line-height: 1.4;
      padding: 0;
      flex-grow: 0;
    }

    #{$root}__button-wrapper {
      margin: 20px 0 0;
      text-align: center;
      font-size: 15px;

      @media (min-width: $screen-tablet-landscape) {
        margin: 16px 0 0;
        text-align: left;
      }
    }

    #{$root}__button {
      background: transparent;
      display: inline-block;
      width: auto;
      border: 0 none;
      color: $grayDarkColor;
      text-decoration: underline;
      padding: 0;
      letter-spacing: 0;

      &:hover {
        color: $grayDarkColor;
        background: transparent;
        text-decoration: underline;
        box-shadow: none;
      }

      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    img {
      width: 100%;
      display: block;
      height: auto;

      @media (min-width: $screen-tablet-landscape) {
        display: block;
        height: 100%;
        width: auto;
      }
    }

    .image-element {
      display: inline;
      padding: 0;

      @media (min-width: $screen-tablet-landscape) {
        display: flex;
        padding: 0;
        justify-content: center;
        height: 100%;
        flex-direction: column;
      }
    }
  }

  &--parallax {
    height: auto;
    overflow: hidden;
    position: relative;
    max-width: none !important;
    padding: 0 !important;
    margin: -30px 0 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: -40px 0 0;
    }

    #{$root}__link {
      text-decoration: none;
    }

    #{$root}__bg {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .is_safari & {
        background-attachment: inherit;

        @media (min-width: $screen-tablet-portrait) {
          background-attachment: fixed;
        }
      }

      &--mobile {
        display: block;

        @media (min-width: $screen-tablet-portrait) {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @media (min-width: $screen-tablet-portrait) {
          display: block;
        }
      }
    }

    .row.grid__element--bg-color1 &,
    .row.grid__element--bg-color2 &,
    .row.grid__element--bg-color3 &,
    .row.grid__element--bg-color4 &,
    .row.grid__element--bg-color5 & {
      margin: -30px 0;

      @media (min-width: $screen-tablet-portrait) {
        margin: -40px 0;
      }
    }

    #{$root}__box {
      display: flex;
      background: transparent;
      position: relative;
      bottom: auto;
      transform: none;
      text-align: center;
      align-self: center;
      justify-content: center;
      min-height: 300px;
      margin: 20px auto;
      color: $blackColor;

      @media (min-width: $screen-tablet-landscape) {
        margin: 40px auto;
      }

      @media (min-width: $screen-desktop) {
        margin: 80px auto;
        max-width: $maxWidthInner;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      margin-top: 0;
    }
  }

  &__parallax-subline {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .grid__element--teaser & {
    #{$root}__header,
    #{$root}__subline {
      color: var(--grid-element-teaser-text-color);

      a {
        color: var(--grid-element-teaser-text-color);
      }
    }

    #{$root}__img-wrapper,
    #{$root}__box {
      background: var(--grid-element-teaser-background);
    }
  }

  // PLACEHOLDER
  .lazy-load-image__image--not-loaded,
  lazy-load-image {  // stylelint-disable-line
    min-height: 200px;
    display: block;
  }
}
