@import '../../../bps/components/teaserGroupTeaser/teaserGroupTeaser.scss';

.teasergroup_teaser {
  $root: &;

  &__headline {
    color: $whiteColor;
  }

  .grid__element--bg-color1 &,
  .grid__element--bg-color2 & {
    .teasergroup_teaser__headline {
      color: $grayDarkColor;
    }

    .cinema-teaser__headline,
    .carousel__page-info {
      color: $grayDarkColor;
    }

    .carousel__button-icon {
      color: $grayDarkColor;

      &::before {
        color: $grayDarkColor;
        text-shadow: $carouselButtonTextShadow;
      }
    }
  }

  .cinema-teaser__headline {
    font-weight: 300;
    text-transform: uppercase;
  }

  @media (min-width: $screen-desktop) {
    .columns-3 > .teasergroup_teaser__item {
      padding: 0 35px 15px;
    }

    .columns-3 {
      width: calc(100% + (2 * 35px));
      margin-left: -35px;
      margin-right: -35px;
    }
  }

  .tabs-component {
    &-tab {
      color: $whiteColor;

      &:hover {
        color: $whiteColor;
      }

      &.is-active {
        color: $whiteColor;
      }

      &-a {
        padding: 10px 0;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $fontWeightThin;
      }
    }
  }

  h2 {
    color: $whiteColor;
    text-transform: uppercase;
    font-weight: 300;
  }

  & > h2,
  & > h3,
  &__headline {
    @media screen and (min-width: $screen-desktop-sm) {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 300;
    }
  }

  &--asymmetric {
    #{$root}__item {
      &:first-child {
        .image-text-action-teaser--variant1 .image-text-action-teaser__box {
          padding: 0 48px;
        }
      }

      &:nth-child(2) {
        .image-text-action-teaser--variant1 .image-text-action-teaser__box {
          margin: 0 !important;
          max-width: none !important;
          padding: 0 48px;
        }
      }

      .image-text-action-teaser {
        &--variant1 .image-text-action-teaser__box {
          background: #4d3240;
          padding: 0 48px;
        }
      }
    }

    .image-text-action-teaser {
      &--variant1 {
        .image-text-action-teaser {
          &__header {
            color: #fff;
            font-size: 20px;
            line-height: 1.1;
            margin: 25px 0 15px;
          }

          &__subline {
            color: #fff;
            font-size: 14px;
            line-height: 1.3;
          }

          &__button {
            background: none;
            border: none;
            color: #fff;
            font-weight: 800;
            text-decoration: underline;
          }
        }
      }

      &__box {
        background: #ece0d0;
        min-height: 280px;
        padding: 24px 48px 0;
      }

      &__header {
        font-size: 20px;
        line-height: 1.1;
      }
    }
  }
}
